'use client'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material'
import { useRouter } from 'next/navigation'
import { memo, useCallback, useState } from 'react'
import {
  appDayjs,
  formatDate,
  punctuation,
  setHospitalIdToCookie,
} from 'shared'
import { Flex, MainNavigation, PopoverMenu } from 'ui'

import { AppLogo } from '@components/nav/AppLogo'
import { NextLink } from '@components/next/NextLink'

import { useClinicAlias } from '@hooks/useClinicAlias'
import { useHospital } from '@hooks/useHospital'

import { useStaff } from '../../stores/atoms/useStaff'
import { pagesPath } from '../../utils/$path'
import { ButtonLink } from '../button'

import type { SelectChangeEvent } from '@mui/material'
import type { NavItem } from 'shared'

export const HospitalHeader = (): JSX.Element => {
  const { logout, staff } = useStaff()
  const clinicAlias = useClinicAlias()

  const navItems = [
    {
      label: '患者一覧',
      path: pagesPath._clinicAlias(clinicAlias).patients.$url().path,
      disabled: !clinicAlias,
    },
    {
      label: '当日受付リスト',
      path: pagesPath
        ._clinicAlias(clinicAlias)
        .reservations._date(formatDate(new Date()))
        .$url().path,
      disabled: !clinicAlias,
    },
    {
      label: '予約カレンダー',
      path: pagesPath
        ._clinicAlias(clinicAlias)
        .reservations.calendar._date(appDayjs().format('YYYY-MM-DD'))
        .$url().path,
      disabled: !clinicAlias,
    },
  ]

  return (
    <AppBar position="static">
      <Toolbar>
        <Flex alignItems="center" gap={4} flexGrow={1}>
          <AppLogo />
          {
            /* 診療科の選択 */
            staff && <HospitalDepartmentSelect />
          }
        </Flex>
        {staff && (
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              gap: { xs: undefined, sm: 1 },
              alignItems: 'center',
            }}
          >
            <Navigation navItems={navItems} />
            <Settings logout={logout} clinicAlias={clinicAlias} />
          </Box>
        )}
        <Hamburger navItems={navItems} />
      </Toolbar>
    </AppBar>
  )
}

const HospitalDepartmentSelect = () => {
  const router = useRouter()
  const { hospitals, paramsClinicAlias, selectedHospitalValue } = useHospital()

  const handleHospitalChange = (event: SelectChangeEvent) => {
    const currentPath = window.location.pathname
    const queryString = window.location.search

    // origin/[clinicAlias]/ -> origin/[newClinicAlias]/ URLのclinicAliasを置換
    const [newHospitalId, newClinicAlias] =
      event.target.value.split(punctuation)
    const newPath = currentPath.replace(paramsClinicAlias, newClinicAlias)

    setHospitalIdToCookie(newClinicAlias, newHospitalId)
    router.replace(newPath + queryString)
    router.refresh()
  }

  return (
    <Select
      value={selectedHospitalValue}
      onChange={handleHospitalChange}
      size="small"
      sx={{
        minWidth: '200px',
        color: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderColor: 'white',
        },
        '& .MuiSvgIcon-root': { color: 'white' },
      }}
    >
      {hospitals?.map((hospital) => (
        <MenuItem
          key={hospital.id}
          value={`${hospital.id}${punctuation}${hospital.url_alias}`}
        >
          {hospital.name}
        </MenuItem>
      ))}
    </Select>
  )
}

const Navigation = ({ navItems }: { navItems: NavItem[] }) => {
  return (
    <Flex>
      {navItems.map((item) => (
        <ButtonLink
          key={item.label}
          href={item.path}
          buttonProps={{
            disabled: item.disabled,
            color: 'contrast',
            sx: {
              height: '100%',
              width: { sm: '100px', md: 'auto' },
              py: 1,
              px: 2,
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'primary.600',
              },
            },
          }}
          noDecoration
        >
          {item.label}
        </ButtonLink>
      ))}
    </Flex>
  )
}

const Settings = ({
  clinicAlias,
  logout,
}: {
  logout: () => void
  clinicAlias: string
}) => {
  const router = useRouter()
  const loginPath = pagesPath.login.$url().pathname

  return (
    <PopoverMenu
      id="app-menu"
      anchor={
        <IconButton
          sx={{ cursor: 'pointer', color: '#EEE' }}
          data-cy="user-config-dialog-button"
        >
          <PersonOutlineIcon />
        </IconButton>
      }
    >
      {() => [
        <NextLink
          key="edit-account-menu"
          href={
            pagesPath._clinicAlias(clinicAlias).upload_healthchecks.$url().path
          }
          style={{ textDecoration: 'none', color: '#333' }}
        >
          <MenuItem key="upload-health-checks-menu">
            <Flex alignItems="flex-start" gap={1}>
              <UploadFileIcon color="action" sx={{ fontSize: 19 }} />
              <Typography>検査結果のアップロード</Typography>
            </Flex>
          </MenuItem>
        </NextLink>,
        <MenuItem
          key="logout-menu"
          onClick={async () => {
            router.prefetch(loginPath)
            logout()
            router.push(loginPath)
          }}
          data-cy="logout-menu"
        >
          <Flex alignItems="flex-start" gap={1}>
            <LogoutIcon color="action" sx={{ fontSize: 18 }} />
            <Typography fontSize={14}>ログアウト</Typography>
          </Flex>
        </MenuItem>,
      ]}
    </PopoverMenu>
  )
}

type HamburgerProps = {
  navItems: NavItem[]
}
const Hamburger = memo(({ navItems }: HamburgerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleDrawerToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <Box display={{ xs: 'block', sm: 'none' }}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setIsOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="top"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{ style: { width: '100%' } }}
      >
        <MainNavigation navItems={navItems} onClose={handleDrawerToggle} />
      </Drawer>
    </Box>
  )
})

Hamburger.displayName = 'Hamburger'
