import { useTheme } from '@mui/material'
import Link from 'next/link'
import { forwardRef } from 'react'

import type { LinkProps } from 'next/link'

export const NextLink: React.ForwardRefExoticComponent<
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode
      disabled?: boolean
    } & React.RefAttributes<HTMLAnchorElement>
> = forwardRef(({ children, disabled, ...props }, ref) => {
  const theme = useTheme()

  if (disabled) return <>{children}</>
  return (
    <Link
      ref={ref}
      {...props}
      style={{ color: theme.palette.info.dark, ...(props.style ?? {}) }}
    >
      {children}
    </Link>
  )
})

NextLink.displayName = 'NextLink'
